<template>
  <div class="mediaPostForm">
    <form v-on:submit.prevent="saveChanges">
      <div class="row">
        <label for="featured_post">Featured:</label>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            name="featured_post"
            id="featured_post_yes"
            value="1"
            v-model="post.featured"
          />
          <label for="featured_post_yes" class="form-check-label"
            >Yes</label
          >
        </div>
        <div class="form-check" style="margin-left: 15px">
          <input
            type="radio"
            class="form-check-input"
            name="featured_post"
            id="featured_post_no"
            value="0"
            v-model="post.featured"
          />
          <label for="featured_post_no" class="form-check-label"
            >No</label
          >
        </div>
      </div>
      <div class="form-group">
        <label for="title">Category:</label>
        <select
          class="form-control"
          name="category"
          v-model="post.category"
        >
          <option
            v-for="category in categories"
            v-bind:value="category.value"
            v-bind:key="category.value"
          >
            {{ category.title }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label for="title">Title:</label>
        <input
          type="text"
          v-model="post.title"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title') }"
          name="title"
          id="title"
          placeholder="Title"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid title.
        </div>
      </div>

      <div class="form-group">
        <label for="media_slug">Slug:</label>
        <input
          type="text"
          v-model="post.slug"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('media_slug'),
          }"
          name="media_slug"
          id="media_slug"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid category name.
        </div>
      </div>

      <div class="form-group">
        <label for="title_short">Title Short:</label>
        <input
          type="text"
          v-model="post.title_short"
          :class="{ 'form-control': true, 'is-invalid': errors.has('title_short') }"
          name="title_short"
          id="title_short"
          placeholder="Title Short"
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid short title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_title">Meta Title (SEO):</label>
        <input
          type="text"
          v-model="post.meta_title"
          class="form-control"
          name="meta_title"
          id="meta_title"
          placeholder=""
        />
        <div class="invalid-feedback">
          Please provide a valid meta title.
        </div>
      </div>

      <div class="form-group">
        <label for="meta_description">Meta Description (SEO):</label>
        <textarea
          v-model="post.meta_description"
          class="form-control"
          name="meta_description"
          id="meta_description"
          placeholder=""
        ></textarea>
        <div class="invalid-feedback">
          Please provide a valid meta description.
        </div>
      </div>

      <div class="form-group">
        <label for="author">Author:</label>
        <input
          type="text"
          v-model="post.author"
          :class="{ 'form-control': true, 'is-invalid': errors.has('author') }"
          name="author"
          id="author"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid author.
        </div>
      </div>

      <div class="form-group">
        <label for="excerpt_image">Excerpt Image:</label>
        <input
          type="text"
          v-model="post.excerpt_image"
          :class="{
            'form-control': true,
            'is-invalid': errors.has('excerpt_image')
          }"
          name="excerpt_image"
          id="excerpt_image"
          placeholder=""
          v-validate="'required'"
        />
        <div class="invalid-feedback">
          Please provide a valid excerpt image link.
        </div>
      </div>

      <div class="form-group">
        <label for="excerpt">Excerpt:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="excerpt"
          id="excerpt"
          v-model="post.excerpt"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
      </div>

      <div class="form-group">
        <label for="description">Post:</label>
        <editor
          api-key="h6ymu878nq2dmit84wddqdm84to1x90pryg1iec6s96rph86"
          name="body"
          id="body"
          v-model="post.post"
          class="wysiwyg-editor"
          :init="{
            plugins:
              'wordcount link image fullscreen lists textcolor colorpicker a11ychecker advcode formatpainter linkchecker media mediaembed powerpaste tinydrive tinymcespellchecker'
          }"
          toolbar="fontselect | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | fontsizeselect a11ycheck code formatpainter insertfile"
        ></editor>
      </div>

      <div class="form-group">
        <label for="release_date">Release Date:</label>
        <datepicker
          placeholder="Select Date"
          v-model="post.release_date"
          name="uniquename"
          input-class="form-control"
        ></datepicker>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import Editor from "@tinymce/tinymce-vue";
import mediaCategories from "../data/media_categories.json";

export default {
  methods: {
    saveChanges: function() {
      let token = this.$cookies.get("poolfit_admin_session");

      if (token !== null) {
        let options = {
          headers: { "X-Access-Token": token }
        };

        if (!isNaN(this.$route.params.id)) {
          this.$http
            .patch(
              process.env.VUE_APP_ROOT_API + "/media/" + this.$route.params.id,
              this.post,
              options
            )
            .then(response => {
              if (response.status === 200) {
                alert("Record updated successfully");
              }
            });
        } else if (this.$route.params.id == "add") {
          this.$http
            .post(process.env.VUE_APP_ROOT_API + "/media", this.post, options)
            .then(response => {
              if (response.status === 200) {
                alert("Record created successfully");
              }
            });
        }
      }
    }
  },

  data: function() {
    return {
      post: {},
      categories: mediaCategories
    };
  },

  components: {
    datepicker: Datepicker,
    editor: Editor
  },

  created: function() {
    if (!isNaN(this.$route.params.id)) {
      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/media/" + this.$route.params.id)
        .then(response => {
          this.post = response.data;
        });
    } else if (this.$route.params.id !== "add") {
      alert("Invalid parameter.");
    }
  }
};
</script>

<style></style>
